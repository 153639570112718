const FIELDS_SEPARATOR = '^';

export const parseNameFields = (dbAddress = FIELDS_SEPARATOR) => {
  const [firstName = '', lastName= ''] = dbAddress.split(FIELDS_SEPARATOR);

  return `${firstName} ${lastName}`;
};

export const parseAddressFields = (dbName = FIELDS_SEPARATOR) => {
  const [streetName= '', houseNumber= ''] = dbName.split(FIELDS_SEPARATOR);

  return `${streetName} ${houseNumber}`;
};
