import { Auth } from '@aws-amplify/auth';
import { VALID_ADMIN_ROLE } from './constants/roles';

export default {
  // called when the user attempts to log in
  login: ({ username, password }) => Auth.signIn(username, password),
  // called when the user clicks on the logout button
  logout: () => Auth.signOut(),
  // called when the API returns an error
  checkError: ({ status }) => {
    const isError = status === 401 || status === 403;

    return isError ? Auth.signOut() : Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: async () => {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const hasValidRole = cognitoUser.attributes['custom:role'] && cognitoUser.attributes['custom:role'] === VALID_ADMIN_ROLE;
    const hasVerifiedEmail = cognitoUser.attributes.email_verified;
    if (!hasValidRole || !hasVerifiedEmail) {
      await Auth.signOut();
      throw Error('Invalid credentials!');
    }
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => Promise.resolve(),
};
