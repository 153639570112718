export const costmap = {
  covidABTest: "SARS-CoV-2 IgG (COVID-19)",
  covidIgMTest: "SARS-CoV-2 IgM (COVID-19)",
  covidPCRTest: "SARS-CoV-2 PCR (COVID-19)",
  covidTest: "SARS-CoV-2 PCR (COVID-19)",
  englishResults: "Ärztliches Attest",
  invoice: "Firmenrechnung",
  sameDayService: "Ergebnis am selben Tag",
  discount: "Onlinerabatt",
  bloodDraw: "Gratis Blutabnahme bei Onlinebuchung",
  O_DROG: "Drogenscreening im Harn",
  O_CDT: "CDT (Carbohydrate deficient Transferrin)",
  O_CDTPRO: "CDT, GOT (AST), GPT (ALT), GGT, MCV",
  O_HIV: "HIV-Test",
  O_HEPC: "Hepatitis C Antikörper",
  O_DAO: "Diaminooxidase (DAO)",
  O_OMEGA: "Omega 3-, Omega 6-Fettsäuren",
  O_FSMEIGG: "FSME Impftiter",
  O_HAVAK: "Hepatitis A Impftiter",
  O_HBSAK: "Hepatitis B Impftiter",
  O_MASERN: "Masern Impftiter",
  O_MUMPS: "Mumps Impftiter",
  O_ROET: "Röteln Impftiter",
  O_VZV: "Varizellen (VZV) Impftiter",
  O_VITA: "Vitamin A (Retinol)",
  O_VITB1: "Vitamin B1 (Thiamin)",
  O_VITB2: "Vitamin B2 (Riboflavin)",
  O_VITB3: "Vitamin B3 (Niacin)",
  O_VB6: "Vitamin B6 (Pyridoxin)",
  O_FOL: "Vitamin B9 (Folsäure)",
  O_HOLOTC: "Holo-Transcobalamin (aktives Vitamin B12)",
  O_VB12: "Vitamin B12 (Cobalamin)",
  O_VITC: "Vitamin C (Ascorbinsäure)",
  O_VITD: "Vitamin D3 (Cholecalciferol)",
  O_VD2: "Vitamin D2 (Ergocalciferol)",
  O_VITE: "Vitamin E (Tocopherole)",
  O_VITK1: "Vitamin K1 (Phyllochinon)",
  O_VITK2: "Vitamin K2 (Menachinon)",
  O_UBI: "Ubichinon (Coenzym Q10)",
  O_NAV: "Natrium im Vollblut",
  O_KV: "Kalium im Vollblut",
  O_CAV: "Calcium im Vollblut",
  O_MGV: "Magnesium im Vollblut",
  O_FEV: "Eisen im Vollblut",
  O_SEV: "Selen im Vollblut",
  O_ZNV: "Zink im Vollblut",
  O_CUV: "Kupfer im Vollblut",
  O_MINK: "Mineralstoffe/Vollblut klein \n(CuV, KV, MgV, ZnV)",
  O_MINM: "Mineralstoffe/Vollblut mittel \n(CuV, KV, MgV, SeV, ZnV)",
  O_MINVB: "Mineralstoffe/Vollblut groß \n(CaV, CuV, FeV, KV, MgV, NaV, SeV, ZnV)"
}
