import React from 'react';
import {
  Admin,
  Resource,
  Filter,
  DateInput,
  List,
  Datagrid,
  DateField,
  TextField,
  FunctionField,
  useRecordContext
} from 'react-admin';
import authProvider from '../../authProvider';
import dataProvider from '../../dataProvider';
import ListActions from '../../ListActions';
import moment from 'moment';
import { i18nProvider } from '../../locales/i18nProvider';
import { parseAddressFields, parseNameFields } from '../../utils/string-manipulation';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { costmap } from "../../locales/costmap";
import {COST_ORDER_SORT} from "../../constants/costorder";

const SuccessIcon = <CheckIcon style={{ color: 'green' }} />;
const ErrorIcon = <CloseIcon style={{ color: 'red' }} />;

const BookedTestsField = (props) => {
  const record = useRecordContext(props);
  const {sameDayService,invoice,englishResults, ...testCosts} = record.record.costs;
  const bookedTests = Object.keys(testCosts);
  const sortedBookedTests = bookedTests.sort(COST_ORDER_SORT)

  return (
      <ul>
        {sortedBookedTests.map(item => (
            <li key={item}>{costmap[item]}</li>
        ))}
      </ul>
  )
};
const getCostResult = costName => record => record.costs && record.costs[costName] != null ? SuccessIcon : ErrorIcon;
// const getTwoORCostResults = (costName1, costName2) => record => record.costs && (record.costs[costName1] != null || record.costs[costName2] != null) ? SuccessIcon : ErrorIcon;

const App = () => {
  const PostList = (props) => (
    <List
      {...props}
      filters={<PostFilter/>}
      filterDefaultValues={{ date: moment().format('YYYY-MM-DD') }}
      bulkActionButtons={false}
      actions={<ListActions/>}
      exporter={false}
      sort={{ field: 'appointmentStartTime', order: 'ASC' }}
    >
      <Datagrid isRowSelectable={false}>
        <FunctionField source="orderCode" render={record => record.orderCode || '-' } />
        <TextField source="locationTitle" />
        <TextField source="appointmentStartTime" />
        <BookedTestsField source="costs" sortable={false}/>
        {/*<FunctionField source="covidPCRTest" sortable={false} render={getTwoORCostResults('covidTest','covidPCRTest')} />*/}
        {/*<FunctionField source="covidABTest" sortable={false} render={getCostResult('covidABTest')} />*/}
        {/*<FunctionField source="covidIgMTest" sortable={false} render={getCostResult('covidIgMTest')} />*/}
        <FunctionField source="patientName" render={record => parseNameFields(record['patientName'])} />
        <DateField source="patientDOB" />
        <FunctionField source="sameDayService" sortable={false} render={getCostResult('sameDayService')} />
        <FunctionField source="englishResults" sortable={false} render={getCostResult('englishResults')} />
        <TextField source="patientPhoneNumber" />
        <TextField source="patientEmail" />
        <FunctionField source="patientAddress" render={record => parseAddressFields(record['patientAddress'])} />
        <FunctionField source="bookingStatus" render={record => record.bookingStatus === 'PAYMENT_SUCCEEDED' ? SuccessIcon : ErrorIcon } />
      </Datagrid>
    </List>
  );

  const PostFilter = props => (
    <Filter {...props}>
      <DateInput source="date" alwaysOn />
    </Filter>)


  return (
    <Admin i18nProvider={i18nProvider} dataProvider={dataProvider} authProvider={authProvider}>
      <Resource name="schedules" options={{ label: 'Termine'}} list={PostList} />
    </Admin>
  );
};

export default App;
