import Amplify from 'aws-amplify';
import React, { useEffect } from 'react';
import App from './App';

const { REACT_APP_AWS_COGNITO_REGION, REACT_APP_AWS_COGNITO_USER_POOL_ID, REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID } = process.env;

const AppContainer = () => {
  useEffect(() => {
    Amplify.configure({
      Auth: {
        region: REACT_APP_AWS_COGNITO_REGION,
        userPoolId: REACT_APP_AWS_COGNITO_USER_POOL_ID,
        userPoolWebClientId: REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
      },
    });
  }, []);

  return <App />;
}

export default AppContainer;
