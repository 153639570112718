export const COST_ORDER = [
    'O_DROG',
    'O_CDT',
    'O_CDTPRO',
    'O_HIV',
    'O_HEPC',
    'O_DAO',
    'O_OMEGA',
    'O_FSMEIGG',
    'O_HAVAK',
    'O_HBSAK',
    'O_MASERN',
    'O_MUMPS',
    'O_ROET',
    'O_VZV',
    'covidTest',
    'covidPCRTest',
    'sameDayService',
    'covidABTest',
    'covidIgMTest',
    'O_VITA',
    'O_VITB1',
    'O_VITB2',
    'O_VITB3',
    'O_VB6',
    'O_FOL',
    'O_HOLOTC',
    'O_VB12',
    'O_VITC',
    'O_VITD',
    'O_VD2',
    'O_VITE',
    'O_VITK1',
    'O_VITK2',
    'O_UBI',
    'O_NAV',
    'O_KV',
    'O_CAV',
    'O_MGV',
    'O_FEV',
    'O_SEV',
    'O_ZNV',
    'O_CUV',
    'O_MINK',
    'O_MINM',
    'O_MINVB',
    'bloodDraw',
    'englishResults',
    'invoice',
    'discount',
];

export const COST_ORDER_SORT = (a, b) => COST_ORDER.indexOf(a) - COST_ORDER.indexOf(b);
