import React, { cloneElement } from 'react';
import {
  useListContext,
  TopToolbar,
  Button,
  sanitizeListRestProps,
} from 'react-admin';
import getPdf from './pdfRequest';

const { REACT_APP_EXPORT_RESULTS } = process.env;
const showExportResultsButton = REACT_APP_EXPORT_RESULTS?.toString() === 'true';

const ListActions = ({ className, exporter, filters, maxResults, ...rest }) => {
  const {
    resource,
    displayedFilters,
    filterValues,
    showFilter,
  } = useListContext();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      {/* Add your custom actions */}

      {
        showExportResultsButton && <Button
          onClick={() => getPdf(filterValues.date)}
          label="export"
        />
      }
    </TopToolbar>
  );
};

export default ListActions;
