import axios from 'axios';
import { getUserToken } from './utils/auth';

const { REACT_APP_API_URL: BASE_URL } = process.env;

const getPdf = async date => {
  const token = await getUserToken();
  const { data } = await axios({
    method: 'POST',
    url: `${BASE_URL}/clinic-admins/schedules/pdf?appointmentDay=${date}`,
    responseType: 'blob',
    headers: {
      authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });

  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `schedule-${date}.pdf`);
  document.body.appendChild(link);
  link.click();
};


export default getPdf;
