import polyglotI18nProvider from 'ra-i18n-polyglot';
import germanMessages from 'ra-language-german';
import englishMessages from 'ra-language-english';
// domain translations
import domainEnglishMessages from './en.json';
import domainGermanMessages from './de.json';

const { REACT_APP_LANGUAGE: appLanguage} = process.env;

const messages = {
  de: { ...germanMessages, ...domainGermanMessages },
  en: { ...englishMessages, ...domainEnglishMessages },
};

export const i18nProvider = polyglotI18nProvider(locale => messages[locale], appLanguage);
